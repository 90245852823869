<template>
  <div class="content">
    <breadcrumb
      :path="{'Talous': '/talous', 'Korkokatto putosi kymmeneen prosenttiin': ''}"
    />
    <img
      style="width: 100%;"
      :src="require(
        `~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`
      )"
      :alt="this.$route.path.substring(1)"
    >
    <h1>
      Korkokatto putosi nyt kymmeneen prosenttiin
      – toimi näin, jos sinulla on kulutusluottoja
    </h1>
    <p style="font-weight: 900;">
      Netissä myönnettävien lainojen korot laskivat jo toisen kerran
      lyhyen ajan sisällä. Kuluttajien taloutta koettelevan
      koronakriisin myötä hallitus rajoitti vakuudettomien lainojen
      nimelliskorkoja loppuvuoden osalta kymmeneen prosenttiin.
      Vanhojen kulutusluottojen ehdot eivät kuitenkaan muutu automaattisesti.
    </p>
    <p>
      Heinäkuun alusta voimaan tullut uusi korkolaki rajoittaa
      väliaikaisesti vakuudettomien lainojen nimelliskoron maksimissaan
      kymmeneen prosenttiin. Korkokatto on määräaikainen ja voimassa
      vuoden 2020 loppuun ja se koskee uusia lainoja. Vuoden 2021
      alusta lainan korot palaavat lainasopimuksen mukaiselle tasolle.
    </p>
    <p>
      Nyt on hyvä aika varmistaa, että aiemmin otetut lainat ovat
      kilpailukykyisesti hinnoiteltu kilpailuttamalla vanhat lainat.
      Helpoin, nopein ja turvallisin tapa tehdä tämä on kilpailuttaa
      lainat Suomen johtavan lainanvälittäjän
      <nuxt-link to="/">Rahalaitoksen avulla</nuxt-link>.
    </p>
    <p>
      Eri lainantarjoajien välillä on suuriakin eroja lainojen korkojen
      ja myös muiden lainanhoitoon liittyvien kulujen välillä, eikä
      lainanhakija välttämättä osaa itse selvittää, mistä lainaa kannattaa
      hakea. Sen takia moni suomalainen maksaa tälläkin hetkellä lainoistaan
      enemmän kuin tarvitsisi.
    </p>
    <p>
      Heinäkuun 1. päivä voimaan astuneen vakuudettomien lainojen korkoja
      säätelevän lain mukaan lainojen nimelliskorko ei saa ylittää
      10 prosenttia. Lainan muiden kulujen yläraja pysyy entisellään 150
      eurossa vuodessa.
    </p>
    <p>
      Vanhojen, ennen lakimuutosta myönnettyjen lainojen ehtoihin
      lakimuutos ei vaikuta. Siksi jokaisen, jolla on tällä hetkellä
      vakuudettomia kulutusluottoja, on tärkeä tarkistaa niiden korot
      ja tarvittaessa päivittää ne uusien ehtojen mukaisiksi.
    </p>
    <h2>Varmista ettet maksa liikaa korkoja</h2>
    <p>
      Rahalaitos kilpailuttaa lainasi turvallisesti 25 pankilla ja
      lainantarjoajalla, joiden kanssa se tekee yhteistyötä. Sinun
      ei tarvitse tehdä muuta kuin täyttää yksi hakemus
      <nuxt-link to="/">Rahalaitoksen sivuilla</nuxt-link>. Hakemuksen
      lähettäminen ei vielä sido mihinkään, vaan voit rauhassa katsoa,
      millaisia tarjouksia saat, ja päättää sitten, hyväksytkö niistä jonkun.
    </p>
    <p>
      Kilpailuttaminen kannattaa, vaikka vanhojen lainojesi korot olisivatkin
      alle lain määräämän 10 prosentin. On hyvin mahdollista, että löydät
      silti paremman tarjouksen Rahalaitoksen yhteistyökumppaneilta.
      Kilpailuttamisen avulla on mahdollista säästää kymmeniä, jopa satoja
      euroja kuukaudessa.
    </p>
    <p>
      Jos kulutusluottoja on useita, tai jos niiden rinnalla on esimerkiksi
      luottokorttivelkoja, voi kilpailuttamisen yhteydessä hakea myös niiden
      <nuxt-link to="/yhdista-lainat">
        yhdistämistä yhdeksi isommaksilainaksi
      </nuxt-link>.
      Korko on usein suhteessa matalampi, kun lainoja on vain yksi.
      Myös lainanhoitokulut ovat tietysti yhdessä lainassa pienemmät
      kuin useassa.
    </p>
    <p>
      <nuxt-link to="/">
        Kilpailuta lainasi Rahalaitoksella – se vie
        vain muutaman minuutin eikä maksa mitään.
      </nuxt-link>
    </p>
    <span class="miniform-header">Lainan kilpailutus 70 000 € asti</span>
    <mini-form />
    <share
      text="Korkokatto putosi nyt kymmeneen prosenttiin
        – toimi näin, jos sinulla on kulutusluottoja"
    />
  </div>
</template>

<script>
export default {
  layout: 'articles',
  name: 'KorkokattoPutosiKymmeneenProsenttiin',
  components: {
    miniForm: () => import('~/components/subpage/miniform'),
    share: () => import('~/components/subpage/share'),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: `Korkokatto putosi nyt kymmeneen prosenttiin
        – toimi näin, jos sinulla on kulutusluottoja`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Netissä myönnettävien lainojen korot laskivat jo
            toisen kerran lyhyen ajan sisällä. Kuluttajien taloutta
            koettelevan koronakriisin myötä hallitus rajoitti vakuudettomien
            lainojen nimelliskorkoja loppuvuoden osalta kymmeneen prosenttiin.`
        }
      ]
    }
  }
}
</script>
