<script>
import Vue from 'vue'
import businessApplication from '~/components/businessApplication/application'

export default Vue.extend({
  name: 'VaurausApplication',
  extends: businessApplication,
  data () {
    return {
      data: {
        originalSliders: {
          loanDefault: {
            min: 1000,
            max: 250000
          },
          loanVauraus: {
            min: 251000,
            max: 8000000
          },
          periodDefault: {
            min: 1,
            max: 36
          },
          periodVauraus: {
            min: 3,
            max: 72
          }
        }
      },
      form: {
        overLimit: true
      },
      sliders: {
        loanDefault: {
          min: 251000,
          max: 8000000
        },
        periodDefault: {
          min: 3,
          max: 72
        }
      }
    }
  }
})
</script>
