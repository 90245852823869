<template>
  <div class="loan-faq">
    <script type="application/ld+json" v-html="ldJson"></script>
    <p class="title has-text-centered">Usein kysytyt kysymykset</p>
    <accordion :items="questions" :columns="2" />
  </div>
</template>

<script>
import accordion from '~/components/accordion'

export default {
  name: 'FAQAccordion',
  components: {
    accordion
  },
  data () {
    return {
      questions: [
        {
          title: 'Mitä tietoja tarvitsen yrityslainahakemuksen täyttämiseen?',
          content: `Yrityslainahakemuksen täyttäminen on helppoa ja nopeaa. Koska kyseessä on kuluttajalaina,
            tarvitaan hakemuksiin vain hakijan sekä mahdollisen yhteishakijan henkilötiedot.`
        },
        {
          title: 'Kuka voi hakea yrityslainaa?',
          content: `Yrityslainaa voi hakea yli 20-vuotias henkilö, jolla on säännölliset palkka- tai
            eläketulot ja puhtaat luottotiedot. Maksuhäiriömerkinnät ovat este lainansaannille.`
        },
        {
          title: 'Kuinka paljon voin hakea vakuudetonta yrityslainaa?',
          content: `Voit hakea vakuudetonta kulutusluottoa yrityksellesi aina 70 000 euroon asti. Maksuaikaa lainalle
            myönnetään tarpeistasi riippuen 1-15 vuotta.`
        },
        {
          title: 'Vaatiiko yrityslainan hakeminen vakuuksia tai takauksia?',
          content: `Rahalaitoksen välittämissä yrityslainoissa ei tarvita reaalivakuuksia tai takaajia. Lainanhakijan
            oma maksukyky toimii velan vakuutena.`
        },
        {
          title: 'Kuinka nopeasti saan yrityslainan tilille?',
          content: `Lainatarjoukset tulevat Oma Rahalaitos palveluun nopeasti ja yleensä suurin osa tarjouksista saapuu
            parin arkipäivän sisällä. Kun olet hyväksynyt sopivimman yrityslainan ja tehnyt sopimuksen pankin kanssa,
            rahat siirretään ilmoittamallesi pankkitilille usein saman päivän aikana tai viimeistään kahden pankkipäivän
            kuluttua sopimuksen allekirjoituksesta.`
        },
        {
          title: 'Onko lainan hakeminen maksutonta?',
          content: `Yrityslainan hakeminen ja kilpailutus sekä lainatarjousten vertailu on täysin maksutonta, eikä
          sido sinua ottamaan lainaa.`
        },
        {
          title: 'Tein virheen hakemukseen, miten pystyn korjaamaan sen?',
          content: `Paras ja nopein tapa virheen oikaisemiseen on soittaa asiakaspalveluumme p.
            <span class="has-text-link">09 2311 3670</span>. Näin saamme oikean tiedon myös lainanmyöntäjien tietoon.
            Voit olla yhteydessä asiakaspalveluumme myös chatin, Oma Rahalaitos – palvelun
            <a href="/oma/">${this.$domain}/oma</a>  tai sähköpostin
            <a href="mailto:asiakaspalvelu@rahalaitos.fi">asiakaspalvelu@rahalaitos.fi</a> kautta.`
        },
        {
          title: 'Mikä on Oma Rahalaitos -palvelu?',
          content: `Oma Rahalaitos –palvelu on portaali, jossa voi seurata hakemuksen käsittelyä reaaliajassa.
            Palvelussa näet kaikki saamasi lainatarjoukset, joita voit vertailla sekä saat ohjeet lainan nostamiseen.
            Omilla sivuilla <a href="/oma/">${this.$domain}/oma</a> pystyy myös keskustelemaan asiakaspalvelun kanssa
            hakemuksesta ja tarjouksista. Palveluun kirjaudutaan henkilötunnuksella ja salasanalla, joka lähetetään
            matkapuhelimeen ja sähköpostiin. Siksi on erityisen tärkeää, että yhteystiedot on kirjattu hakemukselle
            oikein.`
        },
        {
          title: 'En pääse kirjautumaan Oma Rahalaitos –palveluun?',
          content: `Oma Rahalaitos –palveluun kirjaudutaan hakemuksen täyttämisen jälkeen. Palvelu on avoinna koko
            hakemuksen voimassaoloajan. Mikäli kirjautuminen epäonnistuu, kehotamme olemaan yhteydessä
            asiakaspalveluumme puhelimitse 09 2311 3670 tai chatin kautta.`
        },
        {
          title: 'Miten lainapäätös tehdään ja mitkä asiat siihen vaikuttavat?',
          content: `Rahoitusyhtiöt käsittelevät hakemuksen aina asiakaskohtaisesti. Yrityslainatarjouksen saamiseen
            vaikuttaa lainanhakijan maksukyky sekä maksuhistoria. Täyttämällä hakemuksen sivuillamme saat
            lainapäätöksen välittömästi.`
        },
        {
          title: 'Voiko lainan maksaa aiottua nopeammin pois?',
          content: `Kyllä voi. Ennenaikainen takaisinmaksu ei kuitenkaan lainantarjoajasta riippuen automaattisesti
            vähennä yrityslainasta maksettavia kokonaiskuluja. Muista siis tarkistaa lainasopimuksen ehdoista,
            maksetaanko kuluja ainoastaan toteutuneelta laina-ajalta vai onko lainassa kiinteät kulut laina-ajasta
            riippumatta.`
        },
        {
          title: 'Mitä asiakirjoja tarvitsen hakiessani yrityslainaa',
          content: `Yrityslainan haku kulutusluottona on siitä helppo prosessi, että et tarvitse yrityksen
            y-tunnuksia, tilinpäätöksiä tai muitakaan asiakirjoja. Lainanottajan henkilötiedot riittävät hakemuksen
            tekemiseen.`
        }
      ]
    }
  },
  computed: {
    ldJson () {
      const json = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: []
      }
      this.questions.forEach(question => {
        json.mainEntity.push({
          '@type': 'Question',
          name: question.title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: question.content.replace(/<[^>]*>?/gm, '')
          }
        })
      })
      return JSON.stringify(json)
    }
  }
}
</script>
