<template>
  <div>
    <form class="app" method="post" action="?action=saveContact" @submit.prevent>
      <p class="is-uppercase has-text-weight-bold title is-4">Yrityslainahakemus</p>
      <p v-if="!$store.state.whitelabel" class="subtitle is-6">Lainan hakeminen on maksutonta eikä se sido sinua mihinkään.</p>
      <p v-else class="subtitle is-6">Lainan hakeminen ja vertaileminen on maksutonta eikä se sido sinua mihinkään.</p>
      <div class="columns">
        <div class="column">
          <loan-slider
            key="form.luottoraja"
            ref="luottoraja"
            v-model.number="form.luottoraja"
            v-validate="'required'"
            name="luottoraja"
            :min="sliders.loanDefault.min"
            :max="sliders.loanDefault.max"
            :step="1000"
            :intervals="{ '70%': [100000, 10000] }"
            @sliderEnd="updateGiosgValues"
          />
        </div>
        <div class="column">
          <loan-slider
            key="form.maksuaika"
            ref="maksuaika"
            v-model.number="form.maksuaika"
            v-validate="'required'"
            name="maksuaika"
            title="Laina-aika"
            :min="sliders.periodDefault.min"
            :max="sliders.periodDefault.max"
            :step="1"
            :format="(value) => Math.round(value) + ' kk'"
            @sliderEnd="updateGiosgValues"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <form-checkbox
            key="form.overLimit"
            v-model="form.overLimit"
            data-vv-as="overLimit"
            name="overLimit"
            @input="changeVaurausLimits"
          >
            <span> Haluan hakea yli 250 000 € lainaa </span>
          </form-checkbox>
        </div>
      </div>
      <div class="app-section app-section_header">
        <p class="is-uppercase has-text-weight-bold subtitle is-6">Yleistiedot</p>
        <div class="columns is-multiline">
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="form.yritysnimi"
              v-model="form.yritysnimi"
              v-validate="'required|min:2|max:40'"
              :error="errors.first('yritysnimi')"
              name="yritysnimi"
              label="Yrityksen nimi"
              placeholder="Yrityksen nimi"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="form.etunimi"
              v-model="form.etunimi"
              v-validate="'required|min:2|max:40'"
              :error="errors.first('etunimi')"
              name="etunimi"
              label="Etunimi"
              placeholder="Etunimi"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="form.sukunimi"
              v-model="form.sukunimi"
              v-validate="'required|min:2|max:40'"
              :error="errors.first('sukunimi')"
              name="sukunimi"
              label="Sukunimi"
              placeholder="Sukunimi"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              ref="sotu"
              key="form.sotu"
              v-model="form.sotu"
              v-validate="'required|finnish_ssn|age_min:20|age_max:75'"
              v-uppercase
              :error="errors.first('sotu')"
              name="sotu"
              label="Henkilötunnus"
              placeholder="XXXXXX-XXXX"
              tooltip="Henkilötunnus ilmoitetaan muodossa: ppkkvv-loppuosa, esim. 123456-123A."
              @change="data.applicantAge = calculateAge(form.sotu)"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="form.puhelin"
              v-model="form.puhelin"
              v-validate="'required|phone_stripped'"
              :error="errors.first('puhelin')"
              name="puhelin"
              type="tel"
              label="Puhelinnumero"
              placeholder="esim. 040 123 4567"
              tooltip="Ilmoita puhelinnumerosi."
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-email
              v-model="form.email"
              tooltip="Alustava päätös ilmoitetaan sähköpostitse. Lainatarjous toimitetaan usein sähköisesti."
            />
          </div>
        </div>
        <template v-if="data.firstStep">
          <div class="columns app-handling-notification">
            <div class="column">
              <p class="notification is-primary is-size-6" style="padding: 0.75rem; border-radius: 5px;">
                Lainahakemuksesi on otettu käsittelyyn! Täytäthän tarkentavat tiedot, niin saat lainapäätöksen
                mahdollisimman nopeasti.
              </p>
            </div>
          </div>
          <p class="is-uppercase has-text-weight-bold subtitle is-6">Yrityksen tiedot</p>
          <div class="columns is-multiline">
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.business_address"
                v-model="form.business_address"
                v-validate="'required|max:128'"
                :error="errors.first('business_address')"
                label="Katuosoite"
                name="business_address"
                placeholder="Katuosoite"
                tooltip="Yrityksen katuosoite"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.business_postal"
                v-model="form.business_postal"
                v-validate="'required|digits:5'"
                :error="errors.first('business_postal')"
                label="Postinumero"
                name="business_postal"
                placeholder="Postinumero"
                tooltip="Yrityksen postinumero"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.business_city"
                v-model="form.business_city"
                v-validate="'required|alpha_spaces|min:2|max:128'"
                :error="errors.first('business_city')"
                label="Postitoimipaikka"
                name="business_city"
                placeholder="Postitoimipaikka"
                tooltip="Yrityksen postitoimipaikka"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.ytunnus"
                v-model="form.ytunnus"
                v-validate="'required|business'"
                :error="errors.first('ytunnus')"
                name="ytunnus"
                label="Y-tunnus"
                placeholder="Y-tunnus"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.liikevaihto"
                v-model="form.liikevaihto"
                v-validate="'required|numeric_spaces'"
                :error="errors.first('liikevaihto')"
                :mask="{ mask: masks.euro, guide: false }"
                :icon="['far', 'euro-sign']"
                label="Liikevaihto kuukaudessa"
                name="liikevaihto"
                placeholder="Liikevaihto kuukaudessa"
                tooltip="Edellisen kuukauden liikevaihto"
                type="tel"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <datepicker
                key="form.rekpaiva"
                v-model="form.rekpaiva"
                v-validate="'required'"
                :error="errors.first('rekpaiva')"
                format="yyyy"
                minimum-view="year"
                maximum-view="year"
                initial-view="year"
                name="rekpaiva"
                label="Perustamisvuosi"
                placeholder="Perustamisvuosi"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.business_form"
                v-model="form.business_form"
                v-validate="'required|max:128'"
                :error="errors.first('business_form')"
                name="business_form"
                label="Yritysmuoto"
                placeholder="Yritysmuoto"
              >
                <option>Avoin yhtiö</option>
                <option>Kommandiittiyhtiö</option>
                <option>Osakeyhtiö</option>
                <option>Osuuskunta</option>
                <option>Yksityinen elinkeinonharjoittaja</option>
              </form-select>
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.business_industry"
                v-model="form.business_industry"
                v-validate="'required'"
                :error="errors.first('business_industry')"
                name="business_industry"
                label="Toimiala"
                placeholder="Toimiala"
              >
                <option>Autokorjaamo</option>
                <option>Catering</option>
                <option>Hammaslääkäri</option>
                <option>IT-Palvelut</option>
                <option>Kauneusala</option>
                <option>Koulutus</option>
                <option>Kuljetus ja varastointi</option>
                <option>Lakipalvelut</option>
                <option>Lääkäri</option>
                <option>Maatalous</option>
                <option>Majoitus</option>
                <option>Markkinointi</option>
                <option>Ohjelmistopalvelut</option>
                <option>Optikko</option>
                <option>Pesulapalvelut</option>
                <option>Puutarha</option>
                <option>Rakennus</option>
                <option>Ravintola/Baari</option>
                <option>Ruokakauppa</option>
                <option>Siivous</option>
                <option>Taksi</option>
                <option>Terveys ja urheilu</option>
                <option>Tilitoimisto</option>
                <option>Tukku- ja vähittäiskauppa</option>
                <option>Välineiden vuokraus</option>
                <option>Muu</option>
              </form-select>
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.tili"
                v-model="form.tili"
                v-validate="'required'"
                :error="errors.first('tili')"
                name="tili"
                label="Tilinumero"
                placeholder="Tilinumero"
                tooltip="Ilmoita tilinumerosi, jonne haluat lainan siirrettävän."
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.tarkoitus"
                v-model="form.tarkoitus"
                v-validate="'required'"
                :error="errors.first('tarkoitus')"
                name="tarkoitus"
                label="Lainan tarkoitus"
                placeholder="Lainan tarkoitus"
              >
                <option value="1">Hankinnat</option>
                <option value="2">Tilojen laajennus/uusiminen</option>
                <option value="3">Aiemman velan uudelleenrahoitus</option>
                <option value="4">Työntekijöiden palkkaus</option>
                <option value="5">Käyttöpääoma</option>
                <option value="6">Varaston täyttö</option>
                <option value="7">Markkinointi</option>
                <option value="8">Muu tarkoitus</option>
              </form-select>
            </div>
          </div>
          <p class="is-uppercase has-text-weight-bold subtitle is-6">Hakijan tiedot</p>
          <div class="columns is-multiline">
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.etunimi"
                v-model="form.etunimi"
                name="etunimi"
                label="Etunimi"
                placeholder="Etunimi"
                :disabled="true"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.sukunimi"
                v-model="form.sukunimi"
                name="sukunimi"
                label="Sukunimi"
                placeholder="Sukunimi"
                :disabled="true"
              />
            </div>
            <div class="column is-4 no-padding-bottom is-hidden-touch">
              <!-- Just empty box -->
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.osoite"
                v-model="form.osoite"
                v-validate="'required|max:128'"
                :error="errors.first('osoite')"
                name="osoite"
                label="Kotiosoite"
                placeholder="Kotiosoite"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.postinro"
                v-model="form.postinro"
                v-validate="'required|digits:5'"
                :error="errors.first('postinro')"
                name="postinro"
                type="number"
                label="Postinumero"
                placeholder="Postinumero"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.kaupunki"
                v-model="form.kaupunki"
                v-validate="'required|alpha_spaces|min:2|max:128'"
                :error="errors.first('kaupunki')"
                name="kaupunki"
                label="Postitoimipaikka"
                placeholder="Postitoimipaikka"
              />
            </div>
          </div>
        </template>
      </div>
      <div class="columns app-section">
        <div class="column">
          <form-checkbox
            key="form.ehdot"
            v-model="form.ehdot"
            v-validate="'required:true'"
            :error="errors.first('ehdot')"
            data-vv-as="Ehdot"
            name="ehdot"
          >
            <a href="/yleiset-ehdot" class="has-text-dark is-underline" target="_blank">
              Hyväksyn yleiset ehdot (Lainatuotteet)
            </a>
          </form-checkbox>
          <form-checkbox
            key="form.marketing"
            v-model="form.marketing"
            v-validate="'required'"
            :error="errors.first('marketing')"
            data-vv-as="Markkinointi"
            name="marketing"
          >
            Kyllä kiitos, haluan vastaanottaa tarjouksia ja
            tietoa <span v-if="!$store.state.whitelabel">Rahalaitoksen</span>
            palvelusta ja eduista sähköpostilla ja tekstiviestillä.
          </form-checkbox>
        </div>
        <div class="column is-narrow has-items-end">
          <button
            :disabled="data.submitDisabled || data.submitLoading"
            class="button is-primary is-rounded"
            type="button"
            @click="submit"
          >
            <span>Kilpailuta lainat</span><span v-if="data.submitLoading" class="icon">
              <font-awesome-icon :icon="['fas', 'spinner-third']" spin />
            </span>
          </button>
        </div>
      </div>
      <div v-if="data.applicationError" class="columns">
        <div class="column">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="notification is-danger" v-html="data.applicationError" />
        </div>
      </div>
      <div class="app-section">
        <p class="is-size-7 is-italic has-text-weight-light">
          Yrityslainan hakijalla tulee olla oikeus edustaa yritystä ja lainan hakijan tulee olla vähintään 20-vuotias.
          Yrityslainaan ei vaadita reaalivakuuksia vain henkilötakaus lainanhakijalta.
        </p>
      </div>
    </form>
    <incomplete-pop-up v-if="data.incompletePopUp" :active="data.incompletePopUp" @close="onIncompleteClose" />
  </div>
</template>

<script>
import calculateAge from '~/mixins/calculateAge'
import defaults from '~/mixins/application/defaults'
import handleFieldError from '~/mixins/application/handleFieldError'
import incomplete from '~/mixins/application/incomplete'
import mergeFormData from '~/mixins/application/mergeFormData'
import scrollTo from '~/mixins/action/scrollTo'
import updateGiosgValues from '~/mixins/application/updateGiosgValues'
import { euro } from '~/utils/form/masks'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    datepicker: () => import( '~/components/form/datepicker' ),
    formCheckbox: () => import( '~/components/form/checkbox' ),
    formEmail: () => import( '~/components/form/email' ),
    formInput: () => import( '~/components/form/input' ),
    formSelect: () => import( '~/components/form/select' ),
    incompletePopUp: () =>
      import( '~/components/loanApplication/incompletePopUp' ),
    loanSlider: () => import( '~/components/form/loanSlider' )
  },
  mixins: [
    calculateAge,
    defaults,
    handleFieldError,
    incomplete,
    mergeFormData,
    scrollTo,
    updateGiosgValues
  ],
  data () {
    return {
      data: {
        applicantAge: null,
        firstStep: false,
        incomplete: null,
        submitDisabled: false,
        submitLoading: false,
        applicationError: null,
        incompletePopUp: false
      },
      sliders: {
        loanDefault: {
          min: 1000,
          max: 250000
        },
        loanVauraus: {
          min: 251000,
          max: 8000000
        },
        periodDefault: {
          min: 1,
          max: 48
        },
        periodVauraus: {
          min: 3,
          max: 72
        }
      },
      form: {
        luottoraja: 50000,
        maksuaika: 12,
        business_address: '',
        business_city: '',
        business_form: '',
        business_industry: '',
        business_phone: '',
        business_postal: '',
        email: '',
        etunimi: '',
        kaupunki: '',
        liikevaihto: '',
        osoite: '',
        puhelin: '',
        rekpaiva: '',
        sotu: '',
        sukunimi: '',
        tarkoitus: '',
        tili: '',
        yritysnimi: '',
        ytunnus: '',
        ehdot: false,
        marketing: false,
        overLimit: false
      },
      masks: {
        euro
      }
    }
  },
  computed: {
    // check if starts to fill application
    isFormDirty () {
      return Object.keys( this.fields ).some( ( key ) => {
        if ( ['luottoraja', 'maksuaika'].includes( key ) ) {
          return false
        }
        return this.fields[key].dirty
      } )
    }
  },
  watch: {
    isFormDirty () {
      // google event if starts to fill application
      this.$ga.event( {
        eventCategory: 'businessLoanApplication',
        eventAction: 'started'
      } )
      this.$gtag.event( 'started', {
        event_category: 'businessLoanApplication',
        value: this.form.luottoraja
      } )
    }
  },
  methods: {
    /**
     * Change slider limits if overLimit checked / unchecked.
     */
    changeVaurausLimits () {
      if ( this.form.overLimit ) {
        // Change loan and period limits for Vauraus and remember original values.
        this.data.originalSliders = JSON.parse( JSON.stringify( this.sliders ) )
        this.sliders.loanDefault = this.sliders.loanVauraus
        this.sliders.periodDefault = this.sliders.periodVauraus
        // Animate change
        this.$refs.luottoraja.$el.querySelector( '.loan-slider .noUi-target' ).style.animation = 'pulse-boxshadow 2s'
        this.$refs.maksuaika.$el.querySelector( '.loan-slider .noUi-target' ).style.animation = 'pulse-boxshadow 2s'
      }
      else {
        // Change limits back to original
        this.sliders = JSON.parse( JSON.stringify( this.data.originalSliders ) )
        // Remove animation
        this.$refs.luottoraja.$el.querySelector( '.loan-slider .noUi-target' ).style.animation = ''
        this.$refs.maksuaika.$el.querySelector( '.loan-slider .noUi-target' ).style.animation = ''
      }
      // Update updated values.
      this.$refs.luottoraja.updateMaxValue()
      this.$refs.maksuaika.updateMaxValue()
    },
    /**
     * Handles form submit
     */
    async submit () {
      this.data.submitLoading = true
      const validateResult = await this.$validator.validate()
      if ( !validateResult ) {
        this.data.applicationError = 'Hakemusta ei voida lähettää ennen kuin puuttuvat kentät on korjattu.'
        try {
          this.$el.querySelector( `[name="${this.$validator.errors.items[0].field}"]` ).scrollIntoView()
        }
        catch ( error ) {
          this.$logger.error( error, {
            metaData: {
              'Validator errors': this.$validator.errors
            }
          } )
        }
        this.data.submitLoading = false
        return
      }
      this.clearErrors()
      // Use correct sending type
      if ( this.data.firstStep ) {
        if ( await this.sendApplication() ) {
          this.data.submitDisabled = true
        }
      }
      // Send incomplete application
      else if ( await this.sendIncomplete( 'saveBusinessContact' ) ) {
        // Set first step to done
        this.data.firstStep = true
      }
      this.data.submitLoading = false
    }
  }
}
</script>
